<template>
  <div id="home">
    <v-container id="goals" tag="section" style="max-width: 1168px">
      <div>
        <v-container black--text>
          <v-slide-x-transition appear>
            <v-col align="center" justify="center">
              <h1 v-text="getTranslation(home.title)" />
              <div class="title font-weight-light" v-text="getTranslation(home.subtitle)" />
            </v-col>
          </v-slide-x-transition>
        </v-container>

        <h3>{{ $t('home.welcome') }}</h3>
        <p>
          {{ $t('home.intro') }}
        </p>
        <p>
          {{ $t('home.about-cabin') }}
        </p>

        <h4 class="py-5">{{ $t('home.sincerly') }}</h4>
        <v-img max-width="250" src="/static/steffomarie.webp" />
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Hem',
    meta: [
      { name: 'description', content: 'Majostugan, en mysig plats i Ljusnedal och Funäsdalen' },
    ],
  },
  methods: {
    getTranslation(text) {
      return this.$i18n.t(text);
    },
  },

  components: {
    // Heading: () => import('@/components/Heading'),
    // Testimonial: () => import('@/components/Testimonial'),
  },

  data: () => ({
    home: {
      title: 'home.welcome-title',
      subtitle: 'home.welcome-subtitle',
    },
    stugan: {
      title: 'Stugan',
      subtitle: 'Om du vill veta mer om sovplatser och våra sällsapsytor',
    },
  }),
};
</script>
